import React, { useRef, useLayoutEffect, useState } from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";
import { connect as connectFela } from "react-fela";
import { GatsbyImage as Img } from "gatsby-plugin-image";

import Layout from "components/layout";
import P from "components/typography/P";
import H2 from "components/typography/H2";
import Ul from "components/typography/UL";
import ParagraphTitle from "components/ParagraphTitle";
import colors, { getHexColor } from "theme/colors";

import IntroBlock from "components/IntroBlock";
import Breadcrumb from "components/Breadcrumb";
import Share from "components/Share";
import UnderlinedTitle from "components/UnderlinedTitle";
import fluidValues from "utilities/fluidValues";
import fluidFonts from "utilities/fluidFonts";
import { fontFamilySerif } from "gatsby-theme-fela/fonts";
import { commonMarks } from "utilities/serializers";
import { serializers as linkSerializers } from "components/BaseLink.js";
import SignupForm from "components/SignupForm";

export const query = graphql`
  query ($country: String!, $slug: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
      ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
      edges {
        node {
          ...Jobs
        }
      }
    }

    sanitySafeCoursePage(
      countrySet: { eq: $country }
      slug: { current: { eq: $slug } }
    ) {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      intro
      headerImage {
        asset {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 1440
            placeholder: BLURRED
          )
        }
      }
      slug {
        current
      }
      color

      breadcrumb
      image {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 218, placeholder: BLURRED)
        }
      }
      _rawContent
      contentTitle
      sections {
        ... on SanitySafeRow {
          _type
          item1 {
            ... on SanitySafeCourseList {
              title
              list
            }
          }
          item2 {
            ... on SanitySignupForm {
              _type
              buttonText
              cmListID
              thankyoumessage
              title
              intro
            }
          }
        }
        ... on SanitySafeCourseContentSection {
          _type
          title
          _rawContentSection
        }
        ... on SanitySafeCourseListSection {
          _type
          list1 {
            title
            list
          }
          list2 {
            list
            title
          }
        }
      }
    }
  }
`;

/* Serializers */
const serializers = ({ styles, rules, block }) => {
  return {
    list: (props) => (
      <Ul {...props} type={props.type} extend={{ styles: rules.list }}>
        {props.children}
      </Ul>
    ),
    listItem: (props) => {
      return (
        <P tag={"li"} version={"list"}>
          {props.children}
        </P>
      );
    },
    types: {
      block: (props) => {
        const style = props.node.style;

        if (style === "title") {
          return <H2>{props.children}</H2>;
        }
        if (style === "medium" && block === "specs") {
          return (
            <P version="medium" extend={{ styles: rules.specs }}>
              {props.children}
            </P>
          );
        }
        if (style === "medium") {
          return <P version="medium">{props.children}</P>;
        }
        if (style === "normal") {
          return <P version="body">{props.children}</P>;
        }
        return BlockContent.defaultSerializers.types.block(props);
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      ...commonMarks({ styles, rules }),
    },
  };
};

const styles = (props) => ({
  links: {
    // color:
      // colors[`color${props.data.sanitySafeCoursePage.color || "Human"}900`],
    textDecoration: 'underline'
  },
  container: {
    backgroundColor: "transparent",
    boxSizing: "border-box",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 1440,
    width: "100%",
    positon: "relative",
    ...fluidValues({ paddingLeft: [20, 80], paddingRight: [20, 80] }),
  },
  nologo: {
    extend: {
      condition: !props.data.sanitySafeCoursePage.image,
      style: {
        ...fluidValues({ marginTop: [60, 120] }),
      },
    },
  },
  row: {
    position: "relative",
    boxSizing: "content",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  col10: {
    flexBasis: "100%",
    ipadMiniAndUp: {
      flexBasis: "calc(10 / 12 * 100%)",
    },
  },
  nowrap: {
    flexWrap: "nowrap",
  },
  breadcrumbs: {
    flexBasis: "100%",
    ipadMiniAndUp: {
      flexBasis: "calc(10 / 12 * 100%)",
    },
  },
  title: {
    fontSize: 72,
    flexBasis: 734,
    flexShrink: 1,
  },
  logoContainer: {
    backgroundColor: "transparent",
    boxSizing: "border-box",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    maxWidth: 1440,
    positon: "relative",
    ...fluidValues({ paddingLeft: [20, 80], paddingRight: [20, 80] }),
    marginBottom: -50,
  },
  logog: {
    transform: "translateY(-50%)",
    marginLeft: "75%",
    width: "calc(16.66666% + 169px)",
    maxWidth: 218,
  },
  shareTop: {
    marginLeft: "auto",
    display: "block",
    flex: "1 0 auto",
    textAlign: "right",
    ipadMiniAndDown: {
      display: "none",
    },
  },
  shareTopMobile: {
    flexBasis: "100%",
    ipadMiniAndUp: {
      flexBasis: "calc(10 / 12 * 100%)",
    },
    ...fluidValues({ marginBottom: [20, 32] }),
    display: "none",
    ipadMiniAndDown: {
      display: "block",
    },
    "& a:first-child": {
      marginLeft: 0,
    },
  },
  mobilePadding: {
    phoneOnly: {
      ...fluidValues({
        paddingLeft: [20, 0],
      }),
    },
  },
  intro: {
    marginBottom: "0",
    flexGrow: 0,
    flexShrink: 1,
    marginLeft: "auto",
    flexBasis: "calc(10 / 12 * 100% + 40px)",
    maxWidth: 950,
    ipadMiniAndUp: {
      flexBasis: "89.5%",
    },
  },
  content: {
    zIndex: 1,
    flexGrow: 0,
    flexShrink: 1,
    position: "relative",
    marginLeft: "auto",
    flexBasis: "calc(10 / 12 * 100% + 40px)",
    ...fluidValues({
      marginTop: [32, 90],
    }),
    ipadMiniAndUp: {
      flexBasis: "83%",
    },
    "& *": {
      position: "relative",
      zIndex: 1,
    },
    "& p": {
      "@media(max-width: 600px)": {
        marginBottom: "0.5rem !important",
      },
    },
  },
  circleContainer: {
    position: "relative",
    ...fluidValues({
      marginTop: [22, 90],
      marginBottom: [22, 90],
    }),
    flexGrow: 0,
    flexShrink: 1,
    marginLeft: "auto",
    flexBasis: "calc(10 / 12 * 100% + 40px)",
    ipadMiniAndUp: {
      flexBasis: "89.5%",
    },
  },
  circle: {
    backgroundColor:
      colors[`color${props.data.sanitySafeCoursePage.color || "Human"}200`],
    clipPath: "circle(50% at 50% 100%)",
    transform: "rotate(-14deg) translateY(-50%)",
    transformOrigin: "center",
    zIndex: 0,
    position: "absolute",
    top: "0",
    left: "0",
    ipadMiniAndDown: {
      left: "-15%",
    },
    ipadMiniAndUp: {
      left: "10%",
    },
    ...fluidValues({
      width: [500, 838],
      height: [500, 838],
    }),
  },
  fullCircle: {
    clipPath: "circle(50% at 50% 50%)",
    transform: "none",
    transformOrigin: "default",
  },
  specs: {
    ...fluidValues({ marginBottom: [6, 28] }),
  },
  contentTitle: {
    flexBasis: "100%",
    ipadMiniAndUp: {
      flexBasis: "calc(10 / 12 * 100%)",
    },
    marginTop: 0,
    whiteSpace: "pre-wrap",
    fontFamily: fontFamilySerif,
    fontWeight: 300,
    letterSpacing: 0,
    ...fluidFonts({ fontSize: [18, 52], lineHeight: [24, 64] }),
    extend: {
      ...fluidValues({
        marginBottom: [32, 40],
        marginTop: [16, 90],
      }),
    },
  },
  safeCourseContentSection: {
    marginLeft: "auto",
    flexBasis: "calc(10 / 12 * 100% + 40px)",
    ipadMiniAndUp: {
      flexBasis: "89.5%",
    },
    "& p:last-of-type:has(a.button)": {
      display: "flex",
      flexWrap: "wrap",
      "& a": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  },
  safeCourseContentSectionTitle: {
    marginLeft: "0 !important",
    flexBasis: "calc(10 / 12 * 100% + 40px)",
    ipadMiniAndUp: {
      flexBasis: "89.5%",
    },
  },
  fakeMargin: {
    ...fluidValues({
      marginTop: [90, 200],
    }),
  },
  toto: {
    "@media (max-width: 1199px)": {
      width: "200vw",
      position: "absolute",
      left: "-100vw",
      top: -30,
      height: "calc(100% + 60px)",
      backgroundColor: colors[`color${props.color || "Human"}400`],
    },
    "@media (max-width: 500px)": {
      top: -50,
      height: "calc(100% + 60px)",
    },
  },
  safeCourseListSection: {
    position: "relative",

    onBefore: {
      "@media (min-width: 1200px)": {
        position: "absolute",
        display: "block",
        content: "''",
        left: 0,
        top: 0,
        height: "100%",
        width: "9vw",
        backgroundColor:
          colors[`color${props.data.sanitySafeCoursePage.color || "Human"}400`],
        maxWidth: 129,
      },
    },
    paddingTop: 40,
    ...fluidValues({
      marginBottom: [32, 60],
    }),
    "@media (max-width: 1200px)": {
      backgroundSize: "100% 52%",
      backgroundRepeat: "no-repeat",
    },
  },
  sectionMarginTop: {
    marginTop: -40,
    width: "100%",
  },
  sectionTitleMarginTop: {
    marginTop: -85,
    width: "100%",
  },
  list1: {
    position: "relative",
    flexBasis: "100%",
    "@media (min-width: 1200px)": {
      flexBasis: "43%",
    },
  },
  list2: {
    flexBasis: "100%",
    "@media (max-width: 1200px)": {
      ...fluidValues({
        marginTop: [62, 60],
      }),
    },
    "@media (min-width: 1200px)": {
      flexBasis: "43%",
    },
  },
  listsContainer: {
    justifyContent: "space-between",
    marginLeft: "auto",
    flexBasis: "calc(10 / 12 * 100% + 40px)",
    ipadMiniAndUp: {
      flexBasis: "89.5%",
    },
  },
  list: {
    position: "relative",
  },
  listTitle: {
    position: "relative",
  },
});

const SafeCoursePage = ({ path, pageContext, data, rules, styles }) => {
  const {
    title,
    meta,
    intro,
    headerImage,
    image,
    color,
    _rawContent,
    contentTitle,
    sections,
    breadcrumb,
  } = data.sanitySafeCoursePage;

  const pageColor = colors[`color${color || "Human"}100`];
  const linkColor = colors[`color${color || "Human"}500`];
  const circleRef = useRef();
  const [fullCircle, setFullCircle] = useState(false);
  useLayoutEffect(() => {
    if (circleRef.current) {
      const height = circleRef.current.clientHeight;
      if (height > 500) {
        setFullCircle(true);
      }
    }
  }, []);
  return (
    <Layout
      pageColor={pageColor}
      linkColor={linkColor}
      title={title}
      slug={{ current: path }}
      meta={meta || {}}
      settings={data.settings}
      jobs={data.jobs}
      country={pageContext.country}
    >
      {headerImage && (
        <IntroBlock
          header
          extend={{ styles: rules.header }}
          image={headerImage}
        />
      )}

      {image && (
        <div className={styles.logoContainer}>
          <Img
            image={image.asset?.gatsbyImageData}
            className={styles.logog}
            alt={""}
          />
        </div>
      )}

      <div className={styles.container + " " + styles.nologo}>
        <div className={styles.row}>
          <Share
            extend={{ styles: rules.shareTopMobile }}
            bgColor={"white"}
            url={typeof window !== "undefined" && window.location.href}
            country={"nz"}
          />

          <Breadcrumb
            leftPart={breadcrumb ?? "LEAN-AGILE"}
            rightPart={breadcrumb?.includes('WEBINAR') ? "EVENTS" : "COURSES"}
            extend={{ styles: rules.breadcrumbs }}
          />

          <div
            className={styles.row + " " + styles.col10 + " " + styles.nowrap}
          >
            {title && (
              <UnderlinedTitle style={"newhero"} extend={{ styles: rules.title }}>
                {title}
              </UnderlinedTitle>
            )}

            <Share
              extend={{ styles: rules.shareTop }}
              bgColor={"white"}
              url={typeof window !== "undefined" && window.location.href}
              country={"nz"}
            />
          </div>
        </div>

        {intro && (
          <div className={styles.row}>
            <div
              className={
                styles.row + " " + styles.col10 + " " + styles.mobilePadding
              }
            >
              <P version="large" extend={{ styles: rules.intro }}>
                {intro}
              </P>
            </div>
          </div>
        )}

        {_rawContent && (
          <div className={styles.row}>
            <div
              className={
                styles.row + " " + styles.col10 + " " + styles.mobilePadding
              }
            >
              <div className={styles.circleContainer} ref={circleRef}>
                <div
                  className={
                    styles.circle + " " + (fullCircle ? styles.fullCircle : "")
                  }
                ></div>
                <BlockContent
                  renderContainerOnSingleChild
                  className={styles.content}
                  blocks={_rawContent}
                  serializers={serializers({ rules, styles, block: "specs" })}
                  imageOptions={{ w: 1263, h: 1016, fit: "max" }}
                  projectId={
                    process.env.SANITY_PROJECT_ID ||
                    process.env.GATSBY_SANITY_PROJECT_ID
                  }
                  dataset={
                    process.env.SANITY_DATASET ||
                    process.env.GATSBY_SANITY_DATASET
                  }
                />
              </div>
            </div>
          </div>
        )}

        {contentTitle && (
          <div className={styles.row}>
            <p className={styles.contentTitle}>{contentTitle}</p>
          </div>
        )}
      </div>

      {sections.length > 0 &&
        sections.map((section, index) => {
          if (section._type === "safeCourseContentSection") {
            return (
              <div className={styles.container} key={index}>
                <div className={styles.row}>
                  <div className={styles.row + " " + styles.col10}>
                    {section.title && (
                      <div className={styles.sectionMarginTop}>
                        <ParagraphTitle
                          extend={{
                            container: rules.safeCourseContentSectionTitle,
                          }}
                        >
                          {section.title}
                        </ParagraphTitle>
                      </div>
                    )}
                    <BlockContent
                      className={
                        styles.safeCourseContentSection +
                        " " +
                        styles.mobilePadding
                      }
                      renderContainerOnSingleChild
                      blocks={section._rawContentSection}
                      serializers={serializers({ rules, styles })}
                      imageOptions={{ w: 1263, h: 1016, fit: "max" }}
                      projectId={
                        process.env.SANITY_PROJECT_ID ||
                        process.env.GATSBY_SANITY_PROJECT_ID
                      }
                      dataset={
                        process.env.SANITY_DATASET ||
                        process.env.GATSBY_SANITY_DATASET
                      }
                    />
                  </div>
                </div>
              </div>
            );
          } else if (section._type === "safeCourseListSection") {
            return (
              <div
                className={
                  styles.container + " " + styles.safeCourseListSection
                }
                key={index}
              >
                <div className={styles.row}>
                  <div
                    className={
                      styles.row +
                      " " +
                      styles.col10 +
                      " " +
                      styles.mobilePadding
                    }
                  >
                    <div className={styles.row + " " + styles.listsContainer}>
                      <div className={styles.list1}>
                        <div className={styles.toto}></div>
                        <div className={styles.sectionTitleMarginTop}>
                          <ParagraphTitle
                            extend={{ container: rules.listTitle }}
                          >
                            {section.list1.title}
                          </ParagraphTitle>
                        </div>
                        <Ul extend={{ styles: rules.list }}>
                          {section.list1.list.map((item, i) => {
                            return (
                              <P tag={"li"} version={"list"} key={"list-" + i}>
                                {item}
                              </P>
                            );
                          })}
                        </Ul>
                      </div>
                      <div className={styles.list2}>
                        <div className={styles.sectionTitleMarginTop}>
                          <ParagraphTitle>{section.list2.title}</ParagraphTitle>
                        </div>
                        <Ul extend={{ styles: rules.list }}>
                          {section.list2.list.map((item, y) => {
                            return (
                              <P tag={"li"} version={"list"} key={"list2-" + y}>
                                {item}
                              </P>
                            );
                          })}
                        </Ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else if (section._type === "safeRow") {
            return (
              <div
                className={
                  styles.container + " " + styles.safeCourseListSection
                }
                key={index}
              >
                <div className={styles.row}>
                  <div
                    className={
                      styles.row +
                      " " +
                      styles.col10 +
                      " " +
                      styles.mobilePadding
                    }
                  >
                    <div className={styles.row + " " + styles.listsContainer}>
                      <div className={styles.list1}>
                        <div className={styles.toto}></div>
                        <div className={styles.sectionTitleMarginTop}>
                          <ParagraphTitle
                            extend={{ container: rules.listTitle }}
                          >
                            {section.item1[0].title}
                          </ParagraphTitle>
                        </div>
                        <Ul extend={{ styles: rules.list }}>
                          {section.item1[0].list?.map((item, i) => {
                            return (
                              <P tag={"li"} version={"list"} key={"list-" + i}>
                                {item}
                              </P>
                            );
                          })}
                        </Ul>
                      </div>

                      {section.item2[0] && (
                        <div className={styles.list2}>
                          {/* {section.item2[0].intro && ( */}
                          {/*   <P>{section.item2[0].intro}</P> */}
                          {/* )} */}
                          <SignupForm
                            {...section.item2[0]}
                            bgColor={getHexColor("Human", 400)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null;
        })}

      <div className={styles.fakeMargin}></div>
    </Layout>
  );
};

export default connectFela(styles)(SafeCoursePage);
